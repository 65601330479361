@use '../../styles' as *;

.admin-histories {
  height: 100%;
  padding: 16px 40px;
  overflow-y: scroll;
  background-color: $kite-gray-20;
  min-width: fit-content;

  &-header {
    display: flex;
    justify-content: space-between;
  }

  .rkp-table {
    &__headings,
    &__row-title,
    &__hoverable,
    &no-click {
      grid-template-columns:
        minmax(0, 0.2fr) /* Updated By */
        minmax(0, 0.2fr) /* Updated At */
        minmax(0, 0.5fr) /* Activity */ !important;
    }

    &__headings-title {
      width: auto;
      font-size: 1.25rem;
      font-weight: bold;
      text-align: left;
    }

    /* Table border-radii */
    &__headings-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    /* Table headings */
    &__headings-item {
      justify-content: start;
      overflow: hidden;
      font-size: 0.875rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__cell {
      display: grid !important;
      justify-content: start;
      align-items: start;
      overflow: auto;

      &.Activity {
        text-align: justify;
      }
    }
  }
}
