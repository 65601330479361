@use '../../../styles' as *;

@media print {
  .react-flow {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .react-flow__viewport {
    width: 100%;
    transform: translate(22px, 22px) !important;
    margin: 0 10px;
  }

  .design-flow-notes {
    margin-bottom: 24px;
    &__notes {
      height: auto !important;
      overflow: visible !important;
    }
    ul {
      overflow: visible !important;
    }
  }

  .uc-review-submissions {
    border: none !important;
    box-shadow: none !important;
    &__form {
      h2 {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }

      &__fields {
        grid-template-columns: 1fr !important;
        width: 100% !important;

        &__question {
          break-inside: avoid !important;
          page-break-inside: avoid !important;
          width: 100% !important;
          display: block !important;
          margin-bottom: 15px !important;
        }

        &__question--image {
          .workflow-section-image {
            max-width: 300px !important;
            max-height: 250px !important;
            display: block !important;
            margin: 1rem !important;
          }
        }
      }
    }
    max-width: 100% !important;
  }
}

.uc-review-submissions {
  @include uc-review-content;

  &__header-container {
    padding-bottom: 20px;
  }

  &__content {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 60px 0 60px;
  }

  &__form {
    text-align: left;

    h3 {
      font-weight: 500;
      font-size: 24px;
      width: 100%;
    }

    &__fields {
      padding-inline-start: 26px;
      margin-bottom: 24px;
      display: grid;
      grid-gap: 0;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      &__question {
        align-self: center;
        margin: 4px 10px 4px 0;
        list-style: none;
        break-inside: avoid;

        &--uc-image-wrapper {
          grid-column: 1 / -1;
        }

        &--image {
          img {
            max-width: 400px;
            margin: 1rem;
          }

          .workflow-section-image {
            max-width: 400px;
            margin: 1rem;
            display: block;
          }
        }
      }

      ul {
        margin-top: 8px;
        list-style: disc;
      }

      &__notes {
        width: 100%;
        margin-bottom: 24px;
        grid-column: 1 / -1;
        break-inside: avoid;

        &--content {
          height: 118px;
          padding: 8px;
          border: 1px solid $kite-gray-30;
        }
      }
    }
  }

  .react-flow {
    overflow: visible;

    button {
      display: none !important;
    }

    &__container {
      cursor: default !important;
    }

    &__handle {
      cursor: default !important;
      width: 0;
      height: 0;
      pointer-events: none;
      bottom: -2px !important;
    }
  }

  .design-flow-notes {
    margin-bottom: 24px;
    ul {
      overflow: visible;
    }
  }
}

.noIcon {
  margin-left: 15px;
}

.noNote {
  position: relative;
  left: 13px;
}
