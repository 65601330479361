@use '../../styles' as *;

.data-page {
  @include configuration-child;
  @include kiteAlert;

  &__card {
    margin: 1.5rem 0;
  }

  &__select--fia-static-ip {
    margin-top: 16px;
  }

  &__select--fia-speed {
    @include kiteTooltipOverride;
  }

  &__select--ddos-protection {
    .kite-select__label-container {
      margin-bottom: 24px;
    }

    .kite-select__label-container {
      .kite-select__label {
        margin-bottom: 22px;
      }
      .kite-select__label:after {
        content: 'Select Protection Tier >= the sum of new and existing bandwidth to the location';
        position: absolute;
        left: 24px;
        top: 48px;
        color: #3b3b3b;
      }
    }
  }
}
